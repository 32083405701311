import { useEffect, useState } from 'react';

const useDevice = () => {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (window.matchMedia('(max-width: 639px)').matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return { isMobile };
};

export { useDevice };
