import dynamic from 'next/dynamic';
import { ComponentType, useMemo } from 'react';

import { camelize } from 'holded/lib/utils';

type Props = {
  icon: string;
  color?: string;
  size?: number;
  outline?: boolean;
  extraClass?: string;
};

const HeroIcon = (props: Props): JSX.Element => {
  const { icon, color = '', size, outline = false, extraClass = '' } = props;

  const iconData = icon?.length > 4 ? (icon.substr(-4) == 'Icon' ? icon : icon + 'Icon') : icon + 'Icon';
  const iconName = camelize(iconData);

  const Icon: ComponentType<{ className: string }> = useMemo(
    () =>
      outline
        ? // third-party library untyped
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dynamic(() => import('@heroicons/react/outline').then((module: any) => module[iconName]))
        : // third-party library untyped
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          dynamic(() => import('@heroicons/react/solid').then((module: any) => module[iconName])),
    [outline, iconName],
  );

  if (iconName == 'NullIcon') {
    return <></>;
  }

  const classes = [`${color && color}`, `h-${size ? size : 6}`, `w-${size ? size : 6}`];

  if (iconName !== undefined && Icon !== undefined) {
    return <Icon className={classes.join(' ') + ' ' + extraClass} aria-hidden="true" />;
  }

  return <></>;
};

export default HeroIcon;
