import getStrapiMedia from 'holded/lib/media';
import { ImageMedia } from 'holded/modules/cms/domain/imageMedia';

type Props = {
  media: ImageMedia;
  width?: number;
  height?: number;
  classImg?: string;
  lazy?: boolean;
};

const NextImage = ({ media, classImg = '', lazy = true, width, height }: Props) => {
  if (!media?.attributes?.url) return <></>;

  const urlImg = getStrapiMedia(media.attributes.url);
  const formats = media?.attributes.formats
    ? urlImg +
      ', ' +
      Object.values(media?.attributes.formats)
        .map((el) => getStrapiMedia(el.url))
        .join(', ')
    : urlImg;
  const alt = media?.attributes.alternativeText ?? '';

  return (
    <img
      className={`${classImg} max-w-full h-auto`}
      src={urlImg}
      loading={lazy ? 'lazy' : 'eager'}
      alt={alt}
      height={height ?? '100%'}
      width={width ?? '100%'}
      srcSet={formats}
    />
  );
};

export default NextImage;
